import './categories.styles.scss';
import Directory from './components/directory/directory.component';
const App = () => {


  return (
    <Directory />

  );
}

export default App;
